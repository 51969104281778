import { useEffect } from 'react';

interface MemoryInfo {
  usedJSHeapSize: number;
  totalJSHeapSize: number;
  jsHeapSizeLimit: number;
}

const getMemoryInfo = (): MemoryInfo | null => {
  if (window.performance && (window.performance as any).memory) {
    const memory = (window.performance as any).memory;
    return {
      usedJSHeapSize: memory.usedJSHeapSize,
      totalJSHeapSize: memory.totalJSHeapSize,
      jsHeapSizeLimit: memory.jsHeapSizeLimit,
    };
  }
  return null;
};

export const useMemoryMonitor = (interval: number = 5000) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const logMemoryUsage = () => {
        const memoryInfo = getMemoryInfo();
        if (memoryInfo) {
          console.log('🥷', {
            used: `${Math.round(memoryInfo.usedJSHeapSize / 1024 / 1024)}MB`,
            total: `${Math.round(memoryInfo.totalJSHeapSize / 1024 / 1024)}MB`,
            limit: `${Math.round(memoryInfo.jsHeapSizeLimit / 1024 / 1024)}MB`,
          });
        }
      };

      const intervalId = setInterval(logMemoryUsage, interval);
      return () => clearInterval(intervalId);
    }
  }, [interval]);
};
