import { Beboer, BeboerType } from '../domain/Beboer';
import { Person } from '../domain/Person';
import { IdType, ISODateString, ISODateTimeString } from '../domain/common';

import { fetchDelete, fetchGet, fetchPost, fetchPut } from './common';
import { isOrganization, isPerson } from './queries/beboereAndBoliger/useBeboereQuery';
import { AddOrgParams, EditOrgParams, Organization } from '../domain/Organization';

export interface AddBeboerPersonParams {
  beboerType: BeboerType;
  bodId?: IdType;
  boligId?: IdType;
  epost?: string;
  invoicingEmail?: string;
  etternavn?: string;
  fornavn?: string;
  id?: IdType;
  innflytningsdato: ISODateString;
  kontonummer?: string;
  mobil?: string;
  nationality: IdType;
  parkeringsplassId?: IdType;
  personnummer?: string;
  rolle: string;
  invoiceRecipient: boolean;
}

export const postBeboer = (
  params: AddBeboerPersonParams | AddOrgParams,
  boligselskapId: string
): Promise<Beboer> =>
  fetchPost(`/api/boligselskaper/${boligselskapId}/beboere/${params.beboerType}`, params);

export interface RequestChangeParams {
  beboerType: BeboerType;
  beboerId: IdType;
  requestBody: string;
}

export const requestBeboerChange = (
  params: RequestChangeParams,
  boligselskapId: string
): Promise<void> =>
  fetchPost(
    `/api/boligselskaper/${boligselskapId}/beboere/${params.beboerType}/${params.beboerId}/henvendelse`,
    { text: params.requestBody }
  );

export const fetchBeboere = (boligselskapId: IdType): Promise<Beboer[]> =>
  fetchGet(`/api/boligselskaper/${boligselskapId}/beboere`).then((result) =>
    result
      ? result.map((item: any) => {
          if (isPerson(item)) {
            return item as Person;
          } else if (isOrganization(item)) {
            return item as Organization;
          } else {
            throw new Error("Item does not match the 'Person' or 'Organization' type.");
          }
        })
      : []
  );

export type EditBeboerParams = {
  boligselskapId: IdType;
  beboerType: BeboerType;
  epost?: string;
  invoicingEmail?: string;
  etternavn?: string;
  beboerId: IdType;
  fornavn?: string;
  gateadresse?: string;
  mobil?: string;
  nationality?: IdType;
  postnummer?: string;
  poststed?: string;
  postland?: IdType;
  passportNumber?: string;
  personnummerTwo?: string;
  passportExpiration?: string;
};

export const updateBeboer = (params: EditBeboerParams | EditOrgParams): Promise<Person> =>
  fetchPut(
    `/api/boligselskaper/${params.boligselskapId}/beboere/${params.beboerType}/${params.beboerId}`,
    params
  );

export type RemovePersonParams = {
  beboerId: IdType;
  beboerType: BeboerType;
};
export const removePersonFromSameie = (
  boligselskapId: IdType,
  params: RemovePersonParams
): Promise<void> =>
  fetchDelete(
    `/api/boligselskaper/${boligselskapId}/beboere/${params.beboerType}/${params.beboerId}`
  );

export type EditBeboerNotatParams = {
  beboerType: BeboerType;
  personId: IdType;
  boligselskapId: IdType;
  notat?: string;
};

export const updateBeboerNotat = (params: EditBeboerNotatParams): Promise<void> =>
  fetchPut(
    `/api/boligselskaper/${params.boligselskapId}/beboere/${params.beboerType}/${params.personId}/notat`,
    params
  );

export interface CreateBeboerOccupancy {
  validFrom: ISODateTimeString;
  validTo?: ISODateTimeString;
}

export interface BeboerOccupancyDto {
  beboer: Beboer;
  boligId: IdType;
  boligselskapId: IdType;
  id: IdType;
  validFrom: ISODateTimeString;
  validTo?: ISODateTimeString;
}

export const fetchBeboerOccupancy = (
  boligselskapId: IdType,
  beboerType: BeboerType,
  beboerId: IdType
): Promise<BeboerOccupancyDto[]> =>
  fetchGet(
    `/api/boligselskaper/${boligselskapId}/beboere/${beboerType}/${beboerId}/tilstedevaerelse`
  );

export const createOccupancy = (
  params: CreateBeboerOccupancy,
  boligselskapId: IdType,
  beboerType: BeboerType,
  beboerId: IdType,
  boligId: IdType
): Promise<void> =>
  fetchPost(
    `/api/boligselskaper/${boligselskapId}/beboere/${beboerType}/${beboerId}/tilstedevaerelse/${boligId}`,
    params
  );

export interface UpdateBeboerOccupancy {
  validFrom: ISODateTimeString;
  validTo?: ISODateTimeString;
  occupancyId: IdType;
}

export const updateOccupancy = (
  params: UpdateBeboerOccupancy,
  boligselskapId: IdType,
  beboerType: BeboerType,
  beboerId: IdType,
  boligId: IdType
): Promise<void> =>
  fetchPut(
    `/api/boligselskaper/${boligselskapId}/beboere/${beboerType}/${beboerId}/tilstedevaerelse/${boligId}/${params.occupancyId}`,
    params
  );

export const deleteOccupancy = (
  boligselskapId: IdType,
  beboerType: BeboerType,
  beboerId: IdType,
  boligId: IdType,
  occupancyId: IdType
): Promise<void> =>
  fetchDelete(
    `/api/boligselskaper/${boligselskapId}/beboere/${beboerType}/${beboerId}/tilstedevaerelse/${boligId}/${occupancyId}`
  );

export type UpdateReservationsCommand = {
  type: BeboerType;
  beboerId: IdType;
  boligselskapId?: IdType;
  sms: boolean;
  email: boolean;
};

export const updateReservations = (reservations: UpdateReservationsCommand): Promise<void> =>
  fetchPut(`/api/${reservations.type}/${reservations.beboerId}/reservations`, {
    boligselskapId: reservations.boligselskapId,
    sms: reservations.sms,
    email: reservations.email,
  });

export interface IdentificationDetails {
  passportExpiration?: string;
  passportNumber?: string;
  personnummerTwo?: string;
}

export const getPersonalIdentificationDetails = (
  boligselskapId: IdType,
  beboerId: IdType
): Promise<IdentificationDetails> =>
  fetchGet(`/api/boligselskaper/${boligselskapId}/beboere/personer/${beboerId}/personligeData`);
