// SRP Code
//
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import config from '../config';
import { fetchDeleteNoRefresh, fetchPostNoRefresh, fetchPutNoRefresh } from './common';
import {
  getAuthenticationHelper,
  getBytesFromHex,
  getNowString,
  getSignatureString,
} from './auth/srp';
import BigInteger from './auth/srp/BigInteger/BigInteger';
import { base64Encoder } from '@aws-amplify/core/internals/utils';
import { epostRegex, telefonRegex } from '../domain/Person';
import * as Sentry from '@sentry/react';

export const CURRENT_PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const NEW_PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

enum LoginType {
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  ILLEGAL = 'ILLEGAL',
}

export const determineLoginType = (input: string): LoginType => {
  const isEmail = epostRegex.test(input);
  const isMobile = telefonRegex.test(input);

  if (isEmail) {
    return LoginType.EMAIL;
  } else if (isMobile) {
    return LoginType.MOBILE;
  } else {
    return LoginType.ILLEGAL;
  }
};

// noinspection JSUnusedGlobalSymbols
export enum AuthChallengeType {
  SMS_MFA = 'SMS_MFA',
  TOTF_MFA = 'TOTF_MFA',
  SELECT_MFA = 'SELECT_MFA',
  SETUP_MFA = 'SETUP_MFA',
  DEVICE_SRP = 'DEVICE_SRP',
  DEVICE_VERIFIER = 'DEVICE_VERIFIER',
  NEW_PASSWORD = 'NEW_PASSWORD',
  ADMIN_NO_SRP = 'ADMIN_NO_SRP',
  PASSWORD_VERIFIER = 'PASSWORD_VERIFIER',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
}

export interface Credentials {
  login: string;
  pwd: string;
}

export interface LoginByCodeParameters {
  login: string;
  code: string;
}

export interface ConfirmPasswordParameters {
  login: string;
  newPassword: string;
  code?: string;
}

export interface RegisterDevice {
  deviceKey: string;
  deviceKeyGroup: string;
}

interface SrpVerificationDto {
  secretBlock: string;
  srpB: string;
  salt?: string | null;
  userIdForSrp?: string | null;
}

interface SoliboTokensDto {
  clientId: string;
  accessToken: string;
  data: string;
  expires: number;
  identity: string;
  refreshToken: string;
  encoded?: string | null;
}

interface SoliboAuthenticationDto {
  userId: string;
  challenge?: AuthChallenge | null;
  deviceKey?: string | null;
  deviceKeyGroup?: string | null;
  session?: string | null;
  srpVerification?: SrpVerificationDto | null;
  tokens?: SoliboTokensDto | null;
}

interface AuthChallenge {
  params: Record<string, string>;
  type: AuthChallengeType;
}

interface RefreshTokensCommand {
  clientId: string;
  fingerprint?: string | null;
}

interface CreateTokensCommand {
  clientId: string;
  email: string | null;
  mobile: string | null;
  fingerprint?: string;
  deviceKey?: string;
  srpA: string;
}

interface VerifySrpCommand {
  clientId: string;
  passwordSignature: string;
  secretBlock: string;
  timestamp: string;
  email: string | null;
  mobile: string | null;
  fingerprint?: string;
  deviceKey?: string;
}

interface RegisterDeviceCommand {
  clientId: string;
  deviceKey: string;
  deviceKeyGroup: string;
  salt?: string;
  verifier?: string;
  fcmToken?: string;
}

interface DeviceSrpCommand {
  clientId: string;
  email?: string | null;
  mobile?: string | null;
  deviceKey?: string | null;
  session: string;
  srpA: string;
  fingerprint: string;
}

interface DeviceVerifySrpCommand {
  clientId: string;
  email?: string | null;
  mobile?: string | null;
  session: string;
  fingerprint: string;
  deviceKey?: string | null;
  secretBlock: string;
  passwordSignature: string;
  timestamp: string;
}

interface MagicCommand {
  clientId: string;
  email: string | null;
  mobile: string | null;
  fingerprint?: string;
  deviceKey?: string;
}

interface ConfirmMagicCommand {
  clientId: string;
  email: string | null;
  mobile: string | null;
  code: string;
  session: string | null;
  deviceKey?: string;
  fingerprint?: string;
}

interface ResetPasswordCommand {
  clientId: string;
  email: string | null;
  mobile: string | null;
  fingerprint?: string;
}

interface ConfirmNewPasswordCommand {
  clientId: string;
  email: string | null;
  mobile: string | null;
  password: string;
  code?: string;
  session?: string;
  fingerprint?: string;
}

export interface SupportParams {
  boligselskapName: string;
  email: string;
}

export const requestSupport = (params: SupportParams): Promise<void> =>
  fetchPostNoRefresh('/api/auth/support', params);

const refreshTokens = (params: RefreshTokensCommand): Promise<SoliboAuthenticationDto> =>
  fetchPutNoRefresh(`/api/auth/implicit/refresh`, params);

const postStartLogin = (params: CreateTokensCommand): Promise<SoliboAuthenticationDto> =>
  fetchPostNoRefresh(`/api/auth/implicit/tokens`, params, true);

const postVerifySrp = (params: VerifySrpCommand): Promise<SoliboAuthenticationDto> =>
  fetchPostNoRefresh(`/api/auth/implicit/tokens/srp`, params);

const postRegisterDevice = (params: RegisterDeviceCommand): Promise<null> =>
  fetchPostNoRefresh(`/api/auth/devices/register`, params);

const postStartDeviceLogin = (params: DeviceSrpCommand): Promise<SoliboAuthenticationDto> =>
  fetchPostNoRefresh(`/api/auth/devices/srp`, params);

const postVerifyDeviceSrp = (params: DeviceVerifySrpCommand): Promise<SoliboAuthenticationDto> =>
  fetchPostNoRefresh(`/api/auth/devices/srp/confirm`, params);

const postResetPassword = (params: ResetPasswordCommand): Promise<null> =>
  fetchPostNoRefresh(`/api/auth/password/reset`, params);

const createTokensByEmail = (params: MagicCommand): Promise<SoliboAuthenticationDto> =>
  fetchPostNoRefresh(`/api/auth/implicit/magic/email`, params, true);

const createTokensBySMS = (params: MagicCommand): Promise<SoliboAuthenticationDto> =>
  fetchPostNoRefresh(`/api/auth/implicit/magic/sms`, params, true);

const confirmCreateTokensByEmail = (
  params: ConfirmMagicCommand
): Promise<SoliboAuthenticationDto> =>
  fetchPutNoRefresh(`/api/auth/implicit/magic/email/confirm`, params);

const confirmCreateTokensBySMS = (params: ConfirmMagicCommand): Promise<SoliboAuthenticationDto> =>
  fetchPutNoRefresh(`/api/auth/implicit/magic/sms/confirm`, params);

const postNewPassword = (params: ConfirmNewPasswordCommand): Promise<null> =>
  fetchPostNoRefresh(`/api/auth/password/confirm`, params);

const revokeToken = (): Promise<null> => fetchDeleteNoRefresh(`/api/auth/implicit/refresh/revoke`);

const authHelper = await getAuthenticationHelper(config.cognitoUserPoolId);

/**
 * creds must use a auth matching either EMAIL_REGEX or MOBILE_REGEX.
 *
 * initiateAuth will return a SoliboAuthenticationDto object upon completion or null on an unsuccessfull password auth, ie. wrong creds.
 *
 * If the user must set a new password, SoliboAuthenticationDto will contain an authchallenge of type "NEW_PASSWORD", upon which you
 * should use the confirmPassword method just as you would when using the forgotpassword function.
 *
 * When using magic auth without password, the next stage will be using the loginByCode function, utilizing the code received on sms or email.
 * This is in the format of a 6 digit OTP, and is passed in with the creds param.
 *
 * The BE will set a cookie which will contain all information as a secure http cookie which is not accesible from javascript. Therefore, a
 * SoliboAuthenticationDto object containing tokens is to be considered a successfull auth, and you can browse normally. This is however
 * sensible to verify through a /user call.
 *
 * @param creds
 */
export const initiateAuth = async (creds: Credentials): Promise<SoliboAuthenticationDto | null> => {
  try {
    localStorage.removeItem('jwt-accesstoken');
    localStorage.removeItem('jwt-identity');
    localStorage.removeItem('jwt-data');
    localStorage.removeItem('assumeId');
    localStorage.removeItem('assumeSelskapId');
  } catch (_) {}

  if (!(creds.login.length === 0 && creds.pwd.length === 0)) {
    const loginType = determineLoginType(creds.login);

    if (creds.pwd.length > 0) {
      if (creds.pwd.trim() === '') {
        throw new Error('Missing password!');
      }

      return await performSRPAuthentication(loginType, creds);
    } else {
      switch (loginType) {
        case LoginType.EMAIL:
          return await magicLogin(loginType, creds.login);
        case LoginType.MOBILE:
          return await magicLogin(loginType, creds.login);
        case LoginType.ILLEGAL:
          throw new Error(`${creds.login} is neither a valid email or a valid phone number`);
        default:
          throw new Error('Cannot happen');
      }
    }
  } else {
    return null;
  }
};

export const loginByLink = async (
  params: LoginByCodeParameters
): Promise<SoliboAuthenticationDto> => loginByCode(LoginType.EMAIL, params);

export const loginByOTP = async (params: LoginByCodeParameters): Promise<SoliboAuthenticationDto> =>
  loginByCode(LoginType.MOBILE, params);

/**
 * creds should include auth (email or sms) and code (6 digit OTP).
 *
 * @param loginType
 * @param params
 */
const loginByCode = async (
  loginType: LoginType,
  params: LoginByCodeParameters
): Promise<SoliboAuthenticationDto> => {
  // @ts-ignore
  const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
    params.login,
    config.cognitoUserPoolId,
    config.cognitoUserPoolClientId
  );

  switch (loginType) {
    case LoginType.EMAIL:
      const emailResult = await confirmCreateTokensByEmail({
        clientId: config.cognitoUserPoolClientId,
        email: params.login,
        mobile: null,
        session: localStorage.getItem('mailSession'),
        code: params.code,
        fingerprint: encodedContextData,
      });

      if (emailResult?.challenge?.type === AuthChallengeType.DEVICE_SRP) {
        await performDeviceSrp(
          loginType,
          {
            login: params.login,
            pwd: '',
          },
          emailResult.session!,
          emailResult.deviceKey!,
          emailResult.deviceKeyGroup!
        );
      } else if (emailResult?.deviceKey && emailResult?.deviceKeyGroup) {
        await registerDevice({
          deviceKey: emailResult.deviceKey,
          deviceKeyGroup: emailResult.deviceKeyGroup,
        });
      }

      try {
        localStorage.removeItem('mailSession');
      } catch (error) {}

      try {
        localStorage.setItem('userLogin', emailResult.userId);
      } catch (error) {}

      return emailResult;
    case LoginType.MOBILE:
      const smsResult = await confirmCreateTokensBySMS({
        clientId: config.cognitoUserPoolClientId,
        email: null,
        mobile: params.login,
        code: params.code,
        session: localStorage.getItem('mobileSession'),
        fingerprint: encodedContextData,
      });

      if (smsResult?.challenge?.type === AuthChallengeType.DEVICE_SRP) {
        await performDeviceSrp(
          loginType,
          {
            login: params.login,
            pwd: '',
          },
          smsResult.session!,
          smsResult.deviceKey!,
          smsResult.deviceKeyGroup!
        );
      } else if (smsResult?.deviceKey && smsResult?.deviceKeyGroup) {
        await registerDevice({
          deviceKey: smsResult.deviceKey,
          deviceKeyGroup: smsResult.deviceKeyGroup,
        });
      }

      try {
        localStorage.removeItem('phoneSession');
      } catch (error) {}

      try {
        localStorage.setItem('userLogin', smsResult.userId);
      } catch (error) {}

      return smsResult;
    default:
      throw Error(`Cannot magic login for ${loginType} with ${params.login}`);
  }
};

export const registerDevice = async (register: RegisterDevice): Promise<Boolean> => {
  await authHelper.generateHashDevice(register.deviceKeyGroup, register.deviceKey);

  await postRegisterDevice({
    clientId: config.cognitoUserPoolClientId,
    deviceKey: register.deviceKey,
    deviceKeyGroup: register.deviceKeyGroup,
    salt: base64Encoder.convert(getBytesFromHex(authHelper.getSaltToHashDevices())),
    verifier: base64Encoder.convert(getBytesFromHex(authHelper.getVerifierDevices())),
  });

  localStorage.setItem('cognitoDevicePass', authHelper.randomPassword!);

  return true;
};

export const revoke = async (): Promise<Boolean> => {
  localStorage.removeItem('userLogin');

  await revokeToken();

  Sentry.setUser({
    email: 'E-post',
    name: `Navn`,
  });

  return true;
};

export const refresh = async (): Promise<Boolean> => {
  await ensureAmazonCognitoSecurityScript();

  // @ts-ignore
  const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
    localStorage.getItem('userLogin') ?? 'unknown',
    config.cognitoUserPoolId,
    config.cognitoUserPoolClientId
  );

  await refreshTokens({
    clientId: config.cognitoUserPoolClientId,
    fingerprint: encodedContextData,
  });

  return true;
};

const ensureAmazonCognitoSecurityScript = async () => {
  const amazonCognitoAdvancedSecurityData = (window as any)
    .AmazonCognitoAdvancedSecurityData as any;
  if (typeof amazonCognitoAdvancedSecurityData !== 'undefined') {
    // Script is already loaded
    return;
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src =
      'https://amazon-cognito-assets.eu-west-1.amazoncognito.com/amazon-cognito-advanced-security-data.min.js';

    script.onload = () => {
      resolve(true);
    };

    script.onerror = (error) => {
      reject(error);
    };

    document.body.appendChild(script);
  });
};

/**
 * creds should include auth (email or mobile).
 *
 * @param creds
 */
export const forgotPassword = async (creds: Credentials): Promise<Boolean> => {
  if (!(creds.login.length === 0 && creds.pwd.length === 0)) {
    const loginType = determineLoginType(creds.login);
    // @ts-ignore
    const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
      creds.login,
      config.cognitoUserPoolId,
      config.cognitoUserPoolClientId
    );

    switch (loginType) {
      case LoginType.EMAIL:
      case LoginType.MOBILE:
        return await resetPassword(loginType, creds.login, encodedContextData);
      default:
        throw new Error('Cannot happen');
    }
  } else {
    throw new Error('No id!');
  }
};

/**
 * creds should include auth (email or mobile) and new password, according to NEW_PASSWORD_REGEX.
 * This should be verified with a old/new check before submitting to ensure no typos on the user end.
 *
 * @param confirm
 */
export const confirmPassword = async (confirm: ConfirmPasswordParameters): Promise<Boolean> => {
  if (!(confirm.login.length === 0 && confirm.newPassword.length === 0)) {
    const loginType = determineLoginType(confirm.login);
    // @ts-ignore
    const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
      confirm.login,
      config.cognitoUserPoolId,
      config.cognitoUserPoolClientId
    );

    switch (loginType) {
      case LoginType.EMAIL:
      case LoginType.MOBILE:
        return await confirmNewPassword(
          loginType,
          confirm.login,
          confirm.newPassword,
          confirm.code,
          encodedContextData
        );
      default:
        throw new Error('Cannot happen');
    }
  } else {
    throw new Error('No id!');
  }
};

const performSRPAuthentication = async (
  loginType: LoginType,
  creds: Credentials
): Promise<SoliboAuthenticationDto | null> => {
  // @ts-ignore
  const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
    creds.login,
    config.cognitoUserPoolId,
    config.cognitoUserPoolClientId
  );

  const srpResult = await startLogin(
    loginType,
    creds.login,
    authHelper.A.toString(16),
    encodedContextData
  );

  if (srpResult) {
    const timestamp = getNowString();
    const session = await authHelper.getPasswordAuthenticationKey({
      username: srpResult.userIdForSrp!,
      password: creds.pwd,
      serverBValue: new BigInteger(srpResult.srpB, 16),
      salt: new BigInteger(srpResult.salt!, 16),
    });
    const signature = getSignatureString({
      username: srpResult.userIdForSrp!,
      userPoolName: config.cognitoUserPoolId,
      challengeParameters: {
        SECRET_BLOCK: srpResult.secretBlock,
      },
      dateNow: timestamp,
      hkdf: session,
    });

    const result = await verifySrp(
      loginType,
      creds.login,
      signature,
      srpResult.secretBlock,
      timestamp,
      encodedContextData
    );

    if (result.challenge?.type === AuthChallengeType.DEVICE_SRP) {
      await performDeviceSrp(
        loginType,
        creds,
        result.session!,
        result.deviceKey!,
        result.deviceKeyGroup!
      );
    } else if (result?.deviceKey && result?.deviceKeyGroup) {
      await registerDevice({
        deviceKey: result.deviceKey,
        deviceKeyGroup: result.deviceKeyGroup,
      });
    }

    try {
      localStorage.setItem('userLogin', creds.login);
    } catch (e) {}

    return result;
  } else {
    return null;
  }
};

const startLogin = async (
  loginType: LoginType,
  login: string,
  srpA: string,
  fingerprint: string
): Promise<SrpVerificationDto | null | undefined> => {
  const command: CreateTokensCommand = {
    clientId: config.cognitoUserPoolClientId,
    email: loginType === LoginType.EMAIL ? login : null,
    mobile: loginType === LoginType.MOBILE ? login : null,
    srpA: srpA,
    fingerprint: fingerprint,
  };

  const start = await postStartLogin(command);

  return start?.srpVerification;
};

const verifySrp = async (
  loginType: LoginType,
  login: string,
  passwordSignature: string,
  passwordSecretBlock: string,
  timestamp: string,
  fingerprint: string
): Promise<SoliboAuthenticationDto> => {
  const command: VerifySrpCommand = {
    clientId: config.cognitoUserPoolClientId,
    email: loginType === LoginType.EMAIL ? login : null,
    mobile: loginType === LoginType.MOBILE ? login : null,
    passwordSignature: passwordSignature,
    secretBlock: passwordSecretBlock,
    timestamp: timestamp,
    fingerprint: fingerprint,
  };

  return await postVerifySrp(command);
};

const performDeviceSrp = async (
  loginType: LoginType,
  creds: Credentials,
  session: string,
  deviceKey: string,
  deviceKeyGroup: string
): Promise<SoliboAuthenticationDto | null> => {
  const deviceAuthHelper = await getAuthenticationHelper(deviceKeyGroup);
  // @ts-ignore
  const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
    creds.login,
    config.cognitoUserPoolId,
    config.cognitoUserPoolClientId
  );
  const devicePassword = localStorage.getItem('cognitoDevicePass')!;
  const srpResult = await startDeviceLogin(
    loginType,
    creds.login,
    deviceKey,
    deviceAuthHelper.A.toString(16),
    session,
    encodedContextData
  );
  const timestamp = getNowString();
  const hkdf = await deviceAuthHelper.getPasswordAuthenticationKey({
    username: deviceKey,
    password: devicePassword,
    serverBValue: new BigInteger(srpResult.srpB, 16),
    salt: new BigInteger(srpResult.salt!, 16),
  });
  const signature = getSignatureString({
    username: deviceKey,
    userPoolName: deviceKeyGroup,
    challengeParameters: {
      SECRET_BLOCK: srpResult.secretBlock,
    },
    dateNow: timestamp,
    hkdf: hkdf,
  });

  try {
    return await verifyDeviceSrp(
      loginType,
      creds.login,
      session,
      deviceKey,
      signature,
      srpResult.secretBlock,
      timestamp,
      encodedContextData
    );
  } catch (err) {
    try {
      localStorage.removeItem('cognitoDevicePass');
    } catch (_) {}

    return null;
  }
};

const startDeviceLogin = async (
  loginType: LoginType,
  login: string,
  deviceKey: string,
  srpA: string,
  session: string,
  fingerprint: string
): Promise<SrpVerificationDto> => {
  const command: DeviceSrpCommand = {
    clientId: config.cognitoUserPoolClientId,
    email: loginType === LoginType.EMAIL ? login : null,
    mobile: loginType === LoginType.MOBILE ? login : null,
    deviceKey: deviceKey,
    srpA: srpA,
    session: session,
    fingerprint: fingerprint,
  };

  try {
    const start = await postStartDeviceLogin(command);

    return start.srpVerification!;
  } catch (err) {
    try {
      localStorage.removeItem('cognitoDevicePass');
    } catch (_) {}

    throw err;
  }
};

const verifyDeviceSrp = async (
  loginType: LoginType,
  login: string,
  session: string,
  deviceKey: string,
  passwordSignature: string,
  passwordSecretBlock: string,
  timestamp: string,
  fingerprint: string
): Promise<SoliboAuthenticationDto> => {
  const command: DeviceVerifySrpCommand = {
    clientId: config.cognitoUserPoolClientId,
    email: loginType === LoginType.EMAIL ? login : null,
    mobile: loginType === LoginType.MOBILE ? login : null,
    session: session,
    deviceKey: deviceKey,
    passwordSignature: passwordSignature,
    secretBlock: passwordSecretBlock,
    timestamp: timestamp,
    fingerprint: fingerprint,
  };

  return await postVerifyDeviceSrp(command);
};

const magicLogin = async (
  loginType: LoginType,
  login: string
): Promise<SoliboAuthenticationDto> => {
  // @ts-ignore
  const encodedContextData = AmazonCognitoAdvancedSecurityData.getData(
    login,
    config.cognitoUserPoolId,
    config.cognitoUserPoolClientId
  );

  switch (loginType) {
    case LoginType.EMAIL:
      const mailResult = await createTokensByEmail({
        clientId: config.cognitoUserPoolClientId,
        email: login,
        mobile: null,
        fingerprint: encodedContextData,
      });

      if (mailResult?.session) {
        localStorage.setItem('mailSession', mailResult?.session);
      }

      return mailResult;
    case LoginType.MOBILE:
      const phoneResult = await createTokensBySMS({
        clientId: config.cognitoUserPoolClientId,
        email: null,
        mobile: login,
        fingerprint: encodedContextData,
      });

      if (phoneResult?.session) {
        localStorage.setItem('phoneSession', phoneResult?.session);
      }

      return phoneResult;
    default:
      throw Error(`Cannot magic login for ${loginType}`);
  }
};

const resetPassword = async (
  loginType: LoginType,
  login: string,
  fingerPrint?: string
): Promise<Boolean> => {
  const command: ResetPasswordCommand = {
    clientId: config.cognitoUserPoolClientId,
    email: loginType === LoginType.EMAIL ? login : null,
    mobile: loginType === LoginType.MOBILE ? login : null,
    fingerprint: fingerPrint,
  };

  sessionStorage.setItem('cognitoResetPasswordLogin', login);

  try {
    await postResetPassword(command);

    return true;
  } catch (e) {
    return false;
  }
};

const confirmNewPassword = async (
  loginType: LoginType,
  login: string,
  password: string,
  code?: string,
  fingerPrint?: string
): Promise<Boolean> => {
  const command: ConfirmNewPasswordCommand = {
    clientId: config.cognitoUserPoolClientId,
    email: loginType === LoginType.EMAIL ? login : null,
    mobile: loginType === LoginType.MOBILE ? login : null,
    password: password,
    code: code,
    fingerprint: fingerPrint,
  };

  try {
    await postNewPassword(command);

    return true;
  } catch (e) {
    return false;
  }
};
